(function () {
  angular
    .module('activation-app', ['app.config.core', 'templates', 'ngMaterial', 'ngMessages'])
    .config(function ($locationProvider) {
      $locationProvider.html5Mode(true);
    })
    .config(configureMaterialTheme);

  function configureMaterialTheme($mdThemingProvider) {
    $mdThemingProvider.theme('default').primaryPalette('blue').accentPalette('blue');
    $mdThemingProvider.theme('dark-blue').backgroundPalette('blue').dark();
  }
})();
