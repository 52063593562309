(function () {
  'use strict';

  function moduleConfig($stateProvider) {
    $stateProvider
      .state('root.platform.agencies', {
        url: '/agencies',
        views: {
          'tabcontent@root.platform': {
            template: '<agencies-list>',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/agencies/sources.js');
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('Agencies');
          },
        },
      })
      .state('root.platform.agency', {
        url: '/agency/:id',
        views: {
          'tabcontent@root.platform': {
            template: '<agency-root id="$resolve.params.id">',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/agencies/sources.js');
          },
          params: function ($stateParams) {
            return $stateParams;
          },
        },
      })
      .state('root.platform.agent', {
        url: '/agent/:id',
        views: {
          'tabcontent@root.platform': {
            template: '<agent-root id="$resolve.params.id">',
          },
        },
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/agencies/sources.js');
          },
          params: function ($stateParams) {
            return $stateParams;
          },
        },
      });
  }

  angular.module('app.agencies', ['ui.router', 'pascalprecht.translate']).config(moduleConfig);
})();
