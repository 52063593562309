(function () {
  'use strict';

  angular
    .module('app.profile', ['app.common', 'ui.router', 'flow'])
    .config(profileModuleConfig)
    .run(profileModuleStateListener);

  function profileModuleConfig($stateProvider) {
    $stateProvider
      .state('profile', {
        url: '/profile',
        abstract: true,
        redirectTo: 'profile.basic',

        views: {
          '@': {
            templateUrl: 'profile/layout.html',
          },
          'header@profile': {
            templateUrl: 'profile/components/header.html',
            controller: 'HeaderController',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/profile/sources.js',
              '/app/js/player/sources.js',
              '/app/js/staff/sources.js',
              '/app/js/communication/sources.js',
            ]);
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('My Profile');
          },
        },
      })
      .state('profile.basic', {
        url: '',
        views: {
          'profile-subview@profile': {
            template:
              '<profile-basics editable="$ctrl.editable" editable-form="profileValidationForm" active-profile="$ctrl.activeProfile" user-profile="$ctrl.userProfile">',
          },
        },
      })
      .state('profile.photo', {
        url: '/photo',
        views: {
          'profile-subview@profile': {
            template:
              '<profile-photo editable="$ctrl.editable" editable-form="profileValidationForm" active-profile="$ctrl.activeProfile">',
          },
        },
      })
      .state('profile.agent', {
        url: '/agent',
        views: {
          'profile-subview@profile': {
            template:
              '<profile-agent editable="$ctrl.editable" editable-form="profileValidationForm" active-profile="$ctrl.activeProfile">',
          },
        },
      })
      .state('profile.career-history', {
        url: '/career/history',
        views: {
          'profile-subview@profile': {
            template: '<staff-career-history staff="$ctrl.activeProfile.profile">',
          },
        },
      })
      .state('profile.career-bio-and-history', {
        url: '/career/bio-and-history',
        views: {
          'profile-subview@profile': {
            template: '<player-overview-bio-and-history player="$ctrl.activeProfile.profile">',
          },
        },
      })
      .state('profile.career-statistics', {
        url: '/career/statistics',
        views: {
          'profile-subview@profile': {
            template: '<player-overview-career-statistics player="$ctrl.activeProfile.profile">',
          },
        },
      })
      .state('profile.career-injuries', {
        url: '/career/injuries',
        views: {
          'profile-subview@profile': {
            template: '<player-overview-career-injuries player="$ctrl.activeProfile.profile">',
          },
        },
      })
      .state('profile.career-transfers', {
        url: '/career/transfers',
        views: {
          'profile-subview@profile': {
            template: '<player-overview-career-transfers player="$ctrl.activeProfile.profile">',
          },
        },
      })
      .state('profile.password', {
        url: '/password',
        views: {
          'profile-subview@profile': {
            template: '<profile-password>',
          },
        },
      });
  }

  function profileModuleStateListener($rootScope, $state) {
    $rootScope.$on('$stateChangeStart', function (event, to, toParams, from, fromParams) {
      var isDestinationProfilePage = /^profile/.test(to.name);
      var isSourceProfilePage = /^profile/.test(from.name);

      var isDestinationProfileBasicPage = to.name === 'profile.basic';

      if (isDestinationProfilePage && !isSourceProfilePage) {
        if (!isDestinationProfileBasicPage) {
          event.preventDefault();
          $state.go('profile.basic');
        }
      }
    });
  }
})();
