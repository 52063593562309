(function () {
  angular.module('app', [
    'ui.bootstrap',
    'oc.lazyLoad',
    'ngSanitize',
    'ngAnimate',
    'ngMaterial',
    'ngTagsInput',
    'ui.tree', // TODO: replace usage with dndLists
    'dndLists',
    'cfp.hotkeys',
    'angular-uuid',
    'monospaced.elastic',
    'duScroll',
    'ladda',
    'LocalForageModule',
    'angular-click-outside',
    'templates',
    'translations',
    'app.agencies',
    'app.organizations',
    'app.common',
    'app.general',
    'app.layout',
    'app.config',
    'app.platform',
    'app.advancedSearch',
    'app.login',
    'app.acl',
    'app.quicksearch',
    'app.countries',
    'app.country',
    'app.competition',
    'app.team',
    'app.player',
    'app.profile',
    'app.communication',
    'app.staff',
    'app.calendar',
    'app.admin',
    'app.settings',
    'app.scouting',
    'app.analytics',
    'app.tagging',
    'app.organisation',
    'app.video',
    'app.game',
    'app.profile',
    'app.help',
    'app.marketplace',
    'app.upload',
    'app.videoTaggingNext',
  ]);

  angular.module('app').config(function ($stateProvider) {
    $stateProvider
      .state('url-not-found', {
        url: '*path',
        templateUrl: '/404.html',
      })
      .state('state-not-found', {
        url: '/404',
        template: '<sc-not-found title="Page"></sc-not-found>',
      });
  });
})();
