(function () {
  function gameModuleConfig($stateProvider) {
    $stateProvider
      .state('root.platform.game', {
        abstract: true,
        url: '/game',
        views: {
          'tabcontent@root.platform': {
            template: '<game-module-root />',
          },
        },
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/game/sources.js',
              '/app/js/team/sources.js',
              '/app/js/player/sources.js',
              '/app/js/video-tagging-next/sources.js',
              '/app/js/advanced-analytics/sources.js',
            ]);
          },
        },
      })
      .state('root.platform.game.advanceAnalytics', {
        url: '/advance-analytics/:home/:away/:gameDate?id',
        views: {
          'content@root.platform.game': {
            template: '<game-detail-advance-analytics-page />',
          },
        },
      })
      .state('root.platform.game.detailguid', {
        url: '/:guid',
        views: {
          'content@root.platform.game': {
            template: '<game-detail-page />',
          },
        },
      })
      .state('root.platform.game.detail', {
        url: '/:home/:away/:gameDate?id',
        views: {
          'content@root.platform.game': {
            template: '<game-detail-page />',
          },
        },
      });
  }

  // TODO: move video module to common
  angular
    .module('app.game', [
      'app.common',
      'app.acl',
      'app.video',
      'app.team',
      'app.player',
      'LocalForageModule',
    ])
    .config(gameModuleConfig);
})();
