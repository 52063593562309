(function () {
  'use strict';

  angular.module('app.countries', ['ui.router']).config(config);

  function config($stateProvider) {
    $stateProvider.state('root.platform.countries', {
      url: '/countries',
      views: {
        'tabcontent@root.platform': {
          templateUrl: 'countries/views/countries.html',
          controller: 'CountriesController',
        },
      },

      resolve: {
        lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
          return MyLazyLoad.load($ocLazyLoad, [
            '/app/js/countries/sources.js',
            '/app/js/game/sources.js',
          ]);
        },
      },
    });
  }
})();
