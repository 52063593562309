function marketplaceModuleConfig($stateProvider) {
  $stateProvider.state('root.marketplace', {
    url: '/marketplace',
    views: {
      'contentView@root': {
        templateUrl: 'marketplace/views/marketplace.html',
        controller: 'MarketplaceController',
        controllerAs: '$ctrl',
      },
    },
    resolve: {
      lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
        return MyLazyLoad.load($ocLazyLoad, '/app/js/marketplace/sources.js');
      },
      title: function (Tabs) {
        Tabs.setWindowTitle('Marketplace');
      },
    },
  });
}

angular
  .module('app.marketplace', ['app.common', 'app.general', 'ui.router'])
  .config(marketplaceModuleConfig);
