'use strict';
(function () {
  function analyticsModuleConfig($stateProvider) {
    $stateProvider.state('root.analytics', {
      url: '/analytics',
      views: {
        'contentView@root': {
          templateUrl: 'analytics/views/analytics.html',
          controller: 'AnalyticsController',
          controllerAs: '$ctrl',
        },
      },
      resolve: {
        lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
          return MyLazyLoad.load($ocLazyLoad, [
            '/app/js/analytics/sources.js',
            '/app/js/advanced-analytics/sources.js',
          ]);
        },
        title: function (Tabs) {
          Tabs.setWindowTitle('Advanced Analytics');
        },
      },
    });
  }

  var analyticsModule = angular.module('app.analytics', [
    'app.common',
    'ui.router',
    'app.advancedAnalytics',
  ]);

  analyticsModule.config(analyticsModuleConfig);
})();
