(function () {
  angular.module('app.admin.common').factory('TemplateFixture', TemplateFixture);

  function TemplateFixture() {
    return {
      getSkills: getSkills,
    };

    function getSkills() {
      // http://www.ehmtheblueline.com/wiki/index.php?title=Attributes:_Players
      return [
        {name: 'Checking', category: 'technical'},
        {name: 'Deflections', category: 'technical'},
        {name: 'Deking', category: 'technical'},
        {name: 'Faceoffs', category: 'technical'},
        {name: 'Hitting', category: 'technical'},
        {name: 'Movement', category: 'technical'},
        {name: 'Passing', category: 'technical'},
        {name: 'Pokecheck', category: 'technical'},
        {name: 'Positioning', category: 'technical'},
        {name: 'Slapshot', category: 'technical'},
        {name: 'Stickhandling', category: 'technical'},
        {name: 'Wristshots', category: 'technical'},
        {name: 'Agitation', category: 'technical'},
        {name: 'Versatility', category: 'technical'},

        {name: 'Aggression', category: 'mental'},
        {name: 'Anticipation', category: 'mental'},
        {name: 'Bravery', category: 'mental'},
        {name: 'Creativity', category: 'mental'},
        {name: 'Determination', category: 'mental'},
        {name: 'Flair', category: 'mental'},
        {name: 'Teamwork', category: 'mental'},
        {name: 'Workrate', category: 'mental'},
        {name: 'Consistency', category: 'mental'},
        {name: 'Decisions', category: 'mental'},
        {name: 'Dirtyness', category: 'mental'},
        {name: 'Important games', category: 'mental'},
        {name: 'Influence', category: 'mental'},
        {name: 'Pass tendency', category: 'mental'},

        {name: 'Acceleration', category: 'physical'},
        {name: 'Balance', category: 'physical'},
        {name: 'Agility', category: 'physical'},
        {name: 'Speed', category: 'physical'},
        {name: 'Stamina', category: 'physical'},
        {name: 'Strength', category: 'physical'},
        {name: 'Fighting', category: 'physical'},
        {name: 'Injury proneness', category: 'physical'},

        {name: 'Adaptibility', category: 'other'},
        {name: 'Ambition', category: 'other'},
        {name: 'Loyality', category: 'other'},
        {name: 'Pressure', category: 'other'},
        {name: 'Professionalism', category: 'other'},
        {name: 'Sportsmanship', category: 'other'},
        {name: 'Temperament', category: 'other'},
      ];
    }
  }
})();
