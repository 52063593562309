angular
  .module('app.platform', ['ui.router', 'app.general', 'pascalprecht.translate', 'app.common'])
  .config(platformModuleConfig);

function platformModuleConfig($stateProvider) {
  $stateProvider.state('root.platform', {
    url: '/platform',
    views: {
      'contentView@root': {
        controller: 'PlatformController',
        templateUrl: 'platform/views/platform.html',
      },
    },

    redirectTo: (params, $injector) => {
      const activeTab = $injector.get('Tabs').getActive();
      let redirectString = activeTab.state;
      if (
        activeTab.params &&
        activeTab.params.constructor === Object &&
        Object.keys(activeTab.params).length !== 0
      ) {
        redirectString = redirectString + '(' + JSON.stringify(activeTab.params) + ')';
      }

      return redirectString;
    },

    resolve: {
      permissions: function (ACLService) {
        return ACLService.hasPermissionsPromise('general:app-access');
      },
      user: function (UserService) {
        return UserService.getPromise();
      },
      lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
        return MyLazyLoad.load($ocLazyLoad, [
          '/app/js/platform/sources.js',
          '/app/js/advanced-search/sources.js',
          '/app/js/communication/sources.js',
        ]);
      },
    },
  });
}
