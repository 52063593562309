(function () {
  'use strict';

  angular
    .module('app.country', [
      'ui.router',
      'app.general',
      'pascalprecht.translate',
      'app.common',
      'app.team',
    ])
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('root.platform.country', {
        url: '/country/:id',
        views: {
          'tabcontent@root.platform': {
            templateUrl: 'country/views/country.html',
            controller: 'CountryController',
            controllerAs: 'country',
          },
        },
        resolve: {
          lazyLoadThisModule: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/country/sources.js',
              '/app/js/team/sources.js',
            ]);
          },
        },
      })
      .state('root.platform.country.organization', {
        url: '/:organizationId',
        views: {
          'tabcontent@root.platform': {
            templateUrl: 'country/views/country.html',
            controller: 'CountryController',
            controllerAs: 'country',
          },
        },
      });
  }
})();
