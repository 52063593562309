(function () {
  function scoutingModuleConfig($stateProvider, ScoutingFilterCriteriaProvider) {
    $stateProvider.state('root.scouting', {
      url: '/scouting',
      redirectTo: 'root.scouting.department',

      views: {
        'contentView@root': {
          templateUrl: 'scouting/views/scouting.html',
        },
      },
      resolve: {
        lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
          return MyLazyLoad.load($ocLazyLoad, [
            '/app/js/player/sources.js',
            '/app/js/team/sources.js',
            '/app/js/admin/sources.js',
            '/app/js/game/sources.js',
            '/app/js/competition/sources.js',
            '/app/js/communication/sources.js',
            '/app/js/advanced-search/sources.js',
          ]);
        },
        title: function (Tabs) {
          Tabs.setWindowTitle('Scouting');
        },
      },
    });

    $stateProvider.state('root.scouting.department', {
      url: '/department?label',
      views: {
        'scouting-content@root.scouting': {
          template: '<scouting-department>',
        },
      },
      resolve: {
        title: function (Tabs) {
          Tabs.setWindowTitle('Scouting Department');
        },
      },
    });

    const possibleFilterNames = ScoutingFilterCriteriaProvider.$get[1]().map(function (item) {
      return item.name;
    });

    $stateProvider.state('root.scouting.player_reports', {
      url: '/reports?' + possibleFilterNames.join('&'),
      views: {
        'scouting-content@root.scouting': {
          template: '<scouting-reports-list preselect="$resolve.params">',
        },
      },
      resolve: {
        title: function (Tabs) {
          Tabs.setWindowTitle('Scouting Reports');
        },
        params: function ($stateParams) {
          return $stateParams;
        },
      },
    });

    $stateProvider.state('root.scouting.report', {
      url: '/report/:id/:player?edit',
      views: {
        'contentView@root': {
          template:
            '<scouting-view-report report-id="$resolve.params.id" player-id="$resolve.params.player" editable="$resolve.params.edit">',
        },
      },
      resolve: {
        title: function (Tabs) {
          Tabs.setWindowTitle('Scouting Report');
        },
        params: function ($stateParams) {
          return $stateParams;
        },
      },
    });

    $stateProvider
      .state('root.scouting.collections', {
        url: '/collections',
        views: {
          'scouting-content@root.scouting': {
            template: '<scouting-collections/>',
          },
        },
        resolve: {
          title: function (Tabs) {
            Tabs.setWindowTitle('SportContract Collections');
          },
        },
      })
      .state('root.scouting.collections.lists', {
        url: '/:id',
        views: {
          'scouting-collection-content': {
            template: `
                        <scouting-collection-lists
                            selected-collection="$collectionCtrl.selectedCollection"
                            available-labels="$collectionCtrl.labels"
                            on-create-label="$collectionCtrl.showCreateLabelModal()"
                        />
                    `,
          },
        },
      })
      .state('root.scouting.collections.lists.players', {
        url: '/:listId',
        views: {
          'scouting-collection-players': {
            template: `
                        <scouting-collection-players
                            selected-collection="$collectionListCtrl.selectedCollection"
                            selected-list="$collectionListCtrl.selectedList"
                            paginator="$collectionListCtrl.paginator"
                            set-paginator="$collectionListCtrl.setPaginator(newPaginator)"
                            available-labels="$collectionListCtrl.availableLabels"
                            on-create-label="$collectionListCtrl.onCreateLabel()"
                        />
                    `,
          },
        },
      });
  }

  angular
    .module('app.scouting', [
      'app.common',
      'app.player',
      'app.game',
      'app.admin.templates',
      'ui.router',
      'textAngular',
    ])
    .config(scoutingModuleConfig);
})();
