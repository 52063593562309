'use strict';
(function () {
  function calendarModuleConfig($stateProvider) {
    $stateProvider.state('root.calendar', {
      url: '/calendar',
      views: {
        'contentView@root': {
          templateUrl: 'calendar/views/calendar.html',
          controller: 'CalendarController',
        },
      },
      resolve: {
        permissions: function (ACLService) {
          return ACLService.hasPermissionsPromise('general:calendar-access');
        },
        lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
          return MyLazyLoad.load($ocLazyLoad, '/app/js/calendar/sources.js');
        },
        title: function (Tabs) {
          Tabs.setWindowTitle('Calendar');
        },
      },
    });
  }

  var calendarModule = angular.module('app.calendar', [
    'app.common',
    'ui.router',
    'angular.filter',
    'colorpicker.module',
    'pascalprecht.translate',
    'ui.bootstrap',
    'ui.bootstrap.tpls',
  ]);

  calendarModule.config(calendarModuleConfig);
})();
