(function () {
  function moduleConfig($stateProvider) {
    $stateProvider.state('root.upload', {
      url: '/upload',
      views: {
        'contentView@root': {
          template: '<video-uploader-react />',
        },
      },
      resolve: {
        permissions: function (ACLService) {
          return ACLService.hasAccountPermissionsPromise('video-upload');
        },
        lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
          return MyLazyLoad.load($ocLazyLoad, ['/app/js/upload/sources.js']);
        },
        title: function (Tabs) {
          Tabs.setWindowTitle('Video Uploader');
        },
      },
    });
  }

  angular.module('app.upload', ['ui.router']).config(moduleConfig);
})();
