'use strict';
(function () {
  function helpModuleConfig($stateProvider) {
    $stateProvider.state('root.help', {
      url: '/help',
      views: {
        'contentView@root': {
          templateUrl: 'help/views/help.html',
          controller: 'HelpController',
        },
        'header@root': {
          templateUrl: 'help/views/header.html',
          controller: 'HeaderController',
        },
      },
      resolve: {
        lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
          return MyLazyLoad.load($ocLazyLoad, '/app/js/help/sources.js');
        },
        title: function (Tabs) {
          Tabs.setWindowTitle('Help');
        },
      },
    });
  }

  var helpModule = angular.module('app.help', ['app.common', 'ui.router']);

  helpModule.config(helpModuleConfig);
})();
