(function () {
  'use strict';

  function moduleConfig($stateProvider) {
    $stateProvider
      .state('root.platform.companies', {
        url: '/companies',
        views: {
          'tabcontent@root.platform': {
            template: '<org-list type="company">',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/organizations/sources.js');
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('Companies');
          },
        },
      })
      .state('root.platform.company', {
        url: '/company/:id',
        views: {
          'tabcontent@root.platform': {
            template: '<org-detail id="$resolve.params.id" type="company">',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/organizations/sources.js');
          },
          params: function ($stateParams) {
            return $stateParams;
          },
        },
      })
      .state('root.platform.unions', {
        url: '/unions',
        views: {
          'tabcontent@root.platform': {
            template: '<org-list type="union">',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/organizations/sources.js');
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('Unions');
          },
        },
      })
      .state('root.platform.union', {
        url: '/union/:id',
        views: {
          'tabcontent@root.platform': {
            template: '<org-detail id="$resolve.params.id" type="union">',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/organizations/sources.js');
          },
          params: function ($stateParams) {
            return $stateParams;
          },
        },
      })
      .state('root.platform.scouting_analytics_organisations', {
        url: '/scouting-and-analytics',
        views: {
          'tabcontent@root.platform': {
            template: '<org-list type="scouting">',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/organizations/sources.js');
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('Scouting & Analytics');
          },
        },
      })
      .state('root.platform.scouting_organization', {
        url: '/scouting-organization/:id',
        views: {
          'tabcontent@root.platform': {
            template: '<org-detail id="$resolve.params.id" type="scouting">',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/organizations/sources.js');
          },
          params: function ($stateParams) {
            return $stateParams;
          },
        },
      });
  }

  angular.module('app.organizations', ['ui.router', 'pascalprecht.translate']).config(moduleConfig);
})();
