(function (global) {
  'use strict';

  global.SportContract = global.SportContract || {};
  global.SportContract.utils = global.SportContract.utils || {};

  global.SportContract.utils.getFieldValue = function (item, field) {
    field = field || '';

    var keys = field.split('.'),
      value = item;

    angular.forEach(keys, function (key) {
      if (value) {
        value = value[key];
      }
    });

    return value;
  };

  global.SportContract.utils.average = function (arr) {
    return (
      _.reduce(
        arr,
        function (memo, num) {
          return memo + num;
        },
        0
      ) / arr.length
    );
  };

  global.SportContract.utils.addSortingPosition = function (data, key) {
    if (!data) {
      return;
    }

    var lastPosition = 1,
      lastFieldValue;

    for (var i = 0; i < data.length; ++i) {
      var item = data[i],
        currentFieldValue = global.SportContract.utils.getFieldValue(item, key);

      if (angular.isDefined(lastFieldValue) && currentFieldValue !== lastFieldValue) {
        lastPosition++;
      }

      item.sortingPosition = lastPosition;
      lastFieldValue = currentFieldValue !== undefined ? currentFieldValue : null;
    }
  };

  global.SportContract.utils.inherit = function (subclass, superclass) {
    var originalPrototype = subclass.prototype;
    subclass.prototype = Object.create(superclass.prototype);

    _.forOwn(originalPrototype, function (property, value) {
      subclass.prototype[value] = property;
    });
  };

  global.SportContract.utils.isBlank = function (value) {
    return typeof value === 'undefined' || value === null || value === '';
  };

  angular
    .module('app.common', ['ui.router', 'ngResource', 'pascalprecht.translate', 'angular-jwt'])
    .config(function ($translateProvider, EventsProvider) {
      EventsProvider.declareEvent(['user:settings-changed']);
    });

  angular.module('app.quicksearch', [
    'ui.router',
    'app.common',
    'pascalprecht.translate',
    'templates',
  ]);

  angular.module('app.video', [
    'ngSanitize',
    'com.2fdevs.videogular',
    'com.2fdevs.videogular.plugins.controls',
    'com.2fdevs.videogular.plugins.buffering',
    'com.2fdevs.videogular.plugins.overlayplay',
  ]);

  angular.module('app.acl', ['app.common', 'ui.router']);
})(window);
