(function () {
  angular.module('app.login', ['ngMessages', 'ui.router', 'app.common']).config(config);

  function config($stateProvider) {
    $stateProvider
      .state('login', {
        url: '/login',
        abstract: true,
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/login/sources.js');
          },
        },
      })
      .state('login.authenticator', {
        url: '',
        views: {
          '@': {
            template: '<login-authenticator />',
          },
        },
        resolve: {
          title: function (Tabs) {
            Tabs.setWindowTitle('Login');
          },
        },
      })
      .state('login.account_chooser', {
        url: '/account',
        views: {
          '@': {
            template: '<login-account-chooser />',
          },
        },
        resolve: {
          title: function (Tabs) {
            Tabs.setWindowTitle('Choose account');
          },
        },
      })
      .state('account_chooser', {
        url: '/accept-updated-tos',
        views: {
          '@': {
            template: '<accept-tos />',
          },
        },
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, '/app/js/login/sources.js');
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('Choose account');
          },
        },
      });
  }
})();
