(function () {
  'use strict';

  function competitionModuleConfig($stateProvider) {
    $stateProvider
      .state('root.platform.competition', {
        abstract: true,
        url: '/competition/:id',
        views: {
          'tabcontent@root.platform': {
            templateUrl: 'competition/views/_layout.html',
            controller: 'CompetitionController as $competitionCtrl',
          },
          'competition-videos-subview@root.platform.competition': {
            templateUrl: 'competition/views/videos.html',
            controller: 'CompetitionVideosController as $videosCtrl',
          },
          'competition-scoring-leaders-subview@root.platform.competition': {
            templateUrl: 'competition/views/scoring-leaders.html',
            controller: 'CompetitionScoringLeadersController as $leadersCtrl',
          },
          'competition-schedule-subview@root.platform.competition': {
            templateUrl: 'competition/views/schedule.html',
            controller: 'CompetitionScheduleController as $scheduleCtrl',
          },
        },
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/competition/sources.js',
              '/app/js/player/sources.js',
              '/app/js/game/sources.js',
              '/app/js/admin/sources.js',
              '/app/js/team/sources.js',
              '/app/js/advanced-analytics/sources.js',
            ]);
          },
        },
      })
      .state('root.platform.competition.overview', {
        url: '',
        abstract: true,
        redirectTo: 'root.platform.competition.overview.teams',
        views: {
          '@root.platform.competition': {
            templateUrl: 'competition/views/overview.html',
          },
          'competition-facts-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/facts.html',
            controller: 'CompetitionFactsController as $factsCtrl',
          },
        },
      })
      .state('root.platform.competition.contacts', {
        url: '/contacts',
        views: {
          '@root.platform.competition': {
            templateUrl: 'competition/views/contacts.html',
          },
        },
      })

      .state('root.platform.competition.overview.teams', {
        url: '',
        views: {
          'competition-details-teams-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/teams.html',
            controller: 'CompetitionTeamsController',
            controllerAs: '$teamsCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.standings', {
        url: '/standings',
        views: {
          'competition-details-standings-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/standings.html',
            controller: 'CompetitionStandingsController',
            controllerAs: '$standingsCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.team_comparison', {
        url: '/team-comparison',
        views: {
          'competition-details-team-comparison-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/team-comparison.html',
            controller: 'CompetitionTeamComparisonController',
            controllerAs: '$comparisonCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.player_stats', {
        url: '/player-stats',
        views: {
          'competition-details-player_stats-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/player-stats.html',
            controller: 'CompetitionPlayerStatsController',
            controllerAs: '$playersCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.top_players', {
        url: '/top-players',
        views: {
          'competition-details-top_players-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/top-players.html',
            controller: 'CompetitionTopPlayersController',
            controllerAs: '$topPlayersCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.free_agents', {
        url: '/free-agents',
        views: {
          'competition-details-free_agents-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/free-agents.html',
            controller: 'CompetitionFreeAgentsController',
            controllerAs: '$freeAgentsCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.next_free_agents', {
        url: '/next-season-free-agents',
        views: {
          'competition-details-next_free_agents-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/next-season-free-agents.html',
            controller: 'CompetitionNextSeasonFreeAgentsController',
            controllerAs: '$nextFreeAgentsCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.injuries', {
        url: '/injuries',
        views: {
          'competition-details-injuries-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/injuries.html',
            controller: 'CompetitionInjuriesController',
            controllerAs: '$injuriesCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.suspensions', {
        url: '/suspensions',
        views: {
          'competition-details-suspensions-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/suspensions.html',
            controller: 'CompetitionSuspensionsController',
            controllerAs: '$suspensionsCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.champions', {
        url: '/champions',
        views: {
          'competition-details-champions-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/champions.html',
            controller: 'CompetitionChampionsController',
            controllerAs: '$championsCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.awards', {
        url: '/awards',
        views: {
          'competition-details-awards-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/awards.html',
            controller: 'CompetitionAwardsController',
            controllerAs: '$awardsCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.transfers', {
        url: '/transfers',
        views: {
          'competition-details-transfers-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/transfers.html',
            controller: 'CompetitionTransfersController',
            controllerAs: '$transfersCtrl',
          },
        },
      })
      .state('root.platform.competition.overview.interesting_facts', {
        url: '/interesting-facts',
        views: {
          'competition-details-interesting_facts-subview@root.platform.competition.overview': {
            templateUrl: 'competition/views/overview/interesting-facts.html',
            controller: 'CompetitionInterestingFactsController',
            controllerAs: '$interestingFactsCtrl',
          },
        },
      })
      .state('root.platform.competition.not_found', {
        url: '',
        views: {
          'tabcontent@root.platform': {
            template: '<sc-not-found title="League"></sc-not-found>',
          },
        },
      });
  }

  var competitionModule = angular.module('app.competition', [
    'app.common',
    'app.general',
    'ui.router',
    'pascalprecht.translate',
  ]);
  competitionModule.config(competitionModuleConfig);
})();
