(function () {
  function taggingModuleConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/tagging', '/tagging/lists');
    $urlRouterProvider.when('/tagging/', '/tagging/lists');
    $urlRouterProvider.when('/tagging/lists', '/tagging/lists/all');
    $urlRouterProvider.when('/tagging/lists/', '/tagging/lists/all');

    $stateProvider
      .state('root.tagging', {
        url: '/tagging',

        views: {
          'contentView@root': {
            template: '<tagging-root />',
          },
        },

        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/tagging/sources.js',
              '/app/js/game/sources.js',
              '/app/js/team/sources.js',
              'app/js/player/sources.js',
              '/app/js/communication/sources.js',
              '/app/js/advanced-search/sources.js',
              '/app/js/admin/sources.js',
            ]);
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('Tagging');
          },
        },
      })
      .state('root.tagging.lists', {
        url: '/lists?play',
        views: {
          'content@root.tagging': {
            template: '<tagging-lists />',
          },
        },
      })
      .state('root.tagging.lists.allPlaylists', {
        url: '/all',
        views: {
          'list@root.tagging.lists': {
            template: '<tagging-list>',
          },
        },
      })
      .state('root.tagging.lists.myPlaylists', {
        url: '/my',
        views: {
          'list@root.tagging.lists': {
            template: '<tagging-list>',
          },
        },
      })
      .state('root.tagging.lists.sharedWithMe', {
        url: '/shared-with-me',
        views: {
          'list@root.tagging.lists': {
            template: '<tagging-list>',
          },
        },
      })
      .state('root.tagging.lists.myDownloads', {
        url: '/my-downloads',
        views: {
          'list@root.tagging.lists': {
            template: '<download-list>',
          },
        },
      })
      .state('root.tagging.tagging', {
        url: '/tagging?list&country&league&season&game&gameId',
        views: {
          'content@root.tagging': {
            template: `
                            <sc-protected-module module="tagging" header="general.general.Tagging" image-placeholder="/img/tagging-module.jpg" >
                                <video-tagging />
                            </sc-protected-module>
                        `,
          },
        },
      })
      .state('root.taggingNew', {
        url: '/tagging-new',
        views: {
          'contentView@root': {
            template: '<video-tagging-react />',
          },
        },
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/tagging/sources.js',
              '/app/js/game/sources.js',
              '/app/js/team/sources.js',
              'app/js/player/sources.js',
              '/app/js/communication/sources.js',
              '/app/js/advanced-search/sources.js',
            ]);
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('Tagging');
          },
        },
      });
  }

  angular
    .module('app.tagging', ['app.common', 'app.game', 'app.team', 'ui.router'])
    .config(taggingModuleConfig);
})();
