/* eslint-disable */
(function () {
  'use strict';

  angular
    .module('app.player', [
      'app.common',
      'app.general',
      'app.scouting',
      'app.admin.templates',
      'ui.router',
      'ui.bootstrap',
      'pascalprecht.translate',
      'app.advancedAnalytics',
    ])
    .config(playerModuleConfig);

  function playerModuleConfig($stateProvider) {
    $stateProvider
      .state('root.platform.player', {
        abstract: true,
        url: '/player/:id',
        views: {
          'tabcontent@root.platform': {
            templateUrl: 'player/views/_layout.html',
            controller: 'PlayerController',
            controllerAs: '$playerCtrl',
          },
          'player-videos-subview@root.platform.player': {
            template:
              '<player-videos player="$playerCtrl.details" season="$playerCtrl.gameSeason" on-season-change="$playerCtrl.changeGameSeason($season)" />',
          },
          'player-schedule-subview@root.platform.player': {
            template:
              '<player-schedule player="$playerCtrl.details" season="$playerCtrl.gameSeason" on-season-change="$playerCtrl.changeGameSeason($season)" />',
          },
        },
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/player/sources.js',
              '/app/js/game/sources.js',
              '/app/js/admin/sources.js',
              '/app/js/communication/sources.js',
              '/app/js/competition/sources.js',
              '/app/js/team/sources.js',
              '/app/js/scouting/sources.js',
              '/app/js/advanced-analytics/sources.js',
            ]);
          },
        },
      })
      .state('root.platform.player.contacts', {
        url: '/contacts',
        views: {
          '@root.platform.player': {
            template:
              '<div>' +
              '<player-contacts player="$playerCtrl.details" />' +
              '<div class="hidden">' +
              '<gamestats-query-selector is-disabled="!$playerCtrl.overallStatsVisible" games="$playerCtrl.games" on-change="$playerCtrl.handleStatsQueryChange($season, $timeQuery, $games)" time-query="\'last_10\'" />' +
              '</div>' +
              '</div>',
          },
        },
      })
      .state('root.platform.player.overview', {
        url: '',
        abstract: true,
        redirectTo: 'root.platform.player.overview.statistics_by_game',
        views: {
          '@root.platform.player': {
            templateUrl: 'player/views/overview.html',
          },
          'player-info-view@root.platform.player.overview': {
            templateUrl: 'player/views/info.html',
          },
        },
      })
      .state('root.platform.player.overview.facts', {
        url: '/facts',
        views: {
          'player-details-facts-subview@root.platform.player.overview': {
            templateUrl: 'player/views/overview/facts.html',
            controller: 'PlayerFactsController',
            controllerAs: 'facts',
          },
          'player-details-statistics-subview@root.platform.player.overview': {
            template: '<player-overview-career-statistics player="$playerCtrl.details">',
          },
        },
      })
      .state('root.platform.player.overview.statistics', {
        url: '/statistics',
        views: {
          'player-details-facts-subview@root.platform.player.overview': {
            templateUrl: 'player/views/overview/facts.html',
            controller: 'PlayerFactsController',
            controllerAs: 'facts',
          },
          'player-details-statistics-subview@root.platform.player.overview': {
            template: '<player-overview-career-statistics player="$playerCtrl.details">',
          },
        },
      })
      .state('root.platform.player.overview.statistics_by_game', {
        url: '',
        views: {
          'player-details-statistics-by-game-subview@root.platform.player.overview': {
            template:
              '<player-statistics-by-game' +
              ' player="$playerCtrl.details" range="$playerCtrl.timeQuery" season="$playerCtrl.gameSeason" stats="$playerCtrl.gameStats" original-stats="$playerCtrl.gameStats" shift-tagging-requests="$playerCtrl.shiftTaggingRequests" game-video-requests="$playerCtrl.gameVideoRequests" strength-filter="$playerCtrl.strengthFilter"' +
              ' on-play-events="$playerCtrl.playEvents($gameEvents, $title)" loading-promise="$playerCtrl.$gameStatsPromise"' +
              ' on-play-game="$playerCtrl.playGame($homeId, $awayId, $date)" on-create-shift-request="$playerCtrl.createShiftRequest($gameStat)" on-create-game-video-request="$playerCtrl.createGameVideoRequest($gameStat)" />',
          },
        },
      })
      .state('root.platform.player.overview.advanced_stats', {
        url: '/advancedstats',
        views: {
          'player-advanced-stats-subview@root.platform.player.overview': {
            templateUrl: 'player/views/overview/advanced_stats.html',
          },
          'player-career-data-subview@root.platform.player.overview': {
            template: '<player-overview-career-data player="$playerCtrl.details">',
          },
        },
      })
      .state('root.platform.player.overview.career_data', {
        url: '/careerdata',
        views: {
          'player-career-data-subview@root.platform.player.overview': {
            template: '<player-overview-career-data player="$playerCtrl.details">',
          },
        },
      })
      .state('root.platform.player.overview.comparison', {
        url: '/comparison',
        views: {
          'player-details-comparison-subview@root.platform.player.overview': {
            templateUrl: 'player/views/overview/comparison.html',
          },
          'player-career-data-subview@root.platform.player.overview': {
            template: '<player-overview-career-data player="$playerCtrl.details">',
          },
        },
      })
      .state('root.platform.player.not_found', {
        url: '',
        views: {
          'tabcontent@root.platform': {
            template: '<sc-not-found title="Player"></sc-not-found>',
          },
        },
      });
  }
})();
