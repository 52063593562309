(function () {
  'use strict';

  function staffModuleConfig($stateProvider) {
    $stateProvider
      .state('root.platform.staff', {
        abstract: true,
        url: '/staff/:id',
        views: {
          'tabcontent@root.platform': {
            template: '<staff-root staff-id="$resolve.params.id">',
          },
        },
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/staff/sources.js',
              '/app/js/communication/sources.js',
            ]);
          },
          params: function ($stateParams) {
            return $stateParams;
          },
        },
      })
      .state('root.platform.staff.contacts', {
        url: '/contacts',
        views: {
          '@root.platform.staff': {
            template: '<staff-contacts staff="$ctrl.staff"></staff-contacts>',
          },
        },
      })
      .state('root.platform.staff.overview', {
        url: '',
        views: {
          '@root.platform.staff': {
            template: '<staff-overview staff="$ctrl.staff"></staff-overview>',
          },
        },
      })
      .state('root.platform.staff.not_found', {
        url: '',
        views: {
          'tabcontent@root.platform': {
            template: '<sc-not-found title="Staff"></sc-not-found>',
          },
        },
      });
  }

  angular
    .module('app.staff', ['app.common', 'app.general', 'ui.router', 'pascalprecht.translate'])
    .config(staffModuleConfig);
})();
