(function () {
  angular.module('app.admin', [
    'app.common',
    'app.general',
    'app.admin.common',
    'app.admin.dashboard',
    'app.admin.billing',
    'app.admin.staffManagement',
    'app.admin.templates',
    'app.admin.userRights',
    'app.admin.groups',
    'app.admin.levels',
    'app.admin.playerRating',
    'app.admin.linkServices',
    'ui.router',
    'ui.router.router',
    'pascalprecht.translate',
    'ui.bootstrap',
    'ui.bootstrap.tpls',
  ]);
})();
(function () {
  angular.module('app.admin.common', [
    'ui.router',
    'ui.router.router',
    'pascalprecht.translate',
    'ui.bootstrap',
    'ui.bootstrap.tpls',
  ]);
})();
(function () {
  var adminDashboardModuleConfig = function ($stateProvider) {
    $stateProvider.state('admin', {
      url: '/admin',
      views: {
        'header@admin': {
          templateUrl: 'admin/common/views/header.html',
          controller: 'AdminHeaderController',
          controllerAs: 'header',
        },
        '@': {
          templateUrl: 'admin/dashboard/views/dashboard.html',
          controller: 'AdminDashboardController',
          controllerAs: 'dashboard',
        },
      },
      resolve: {
        adminLogin: function ($q, $uibModal, UserService) {
          var loggedInAsAdmin = UserService.isLoggedInAsAdmin();

          if (loggedInAsAdmin) {
            return true;
          }

          var deferred = $q.defer();

          var modalInstance = $uibModal.open({
            size: 'sm',
            template:
              '<admin-login-modal modal-instance="$ctrl.modalInstance"></admin-login-modal>',
            controller: [
              '$uibModalInstance',
              function ($uibModalInstance) {
                this.modalInstance = $uibModalInstance;
              },
            ],
            controllerAs: '$ctrl',
          });

          modalInstance.result.then(
            function () {
              deferred.resolve();
            },
            function () {
              deferred.reject();
            }
          );

          return deferred.promise;
        },

        permissions: function (UserService, ACLService) {
          return ACLService.hasAccountPermissionsPromise(['organization-admin-access'], true);
        },
        lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
          return MyLazyLoad.load($ocLazyLoad, [
            '/app/js/admin/sources.js',
            '/app/js/communication/sources.js',
          ]);
        },
        title: function (Tabs) {
          Tabs.setWindowTitle('Admin');
        },
      },
    });
  };

  var adminDashboardModule = angular.module('app.admin.dashboard', [
    'app.common',
    'app.admin.common',
    'ui.router',
    'ui.router.router',
    'pascalprecht.translate',
  ]);
  adminDashboardModule.config(adminDashboardModuleConfig);
})();
(function () {
  angular
    .module('app.admin.staffManagement', [
      'app.common',
      'app.admin.common',
      'ui.router',
      'ui.router.router',
      'pascalprecht.translate',
    ])
    .config(adminStaffModuleConfig);

  function adminStaffModuleConfig($stateProvider) {
    $stateProvider.state('admin.staffManagement', {
      url: '/staff-management',
      views: {
        'content@admin': {
          template: '<staff-management />',
        },
      },
    });
  }
})();
(function () {
  angular
    .module('app.admin.templates', [
      'app.common',
      'app.admin.common',
      'app.communication',
      'ui.router',
      'ui.router.router',
      'pascalprecht.translate',
    ])
    .config(adminTemplatesModuleConfig);

  function adminTemplatesModuleConfig($stateProvider) {
    $stateProvider
      .state('admin.templates', {
        url: '/templates',
        views: {
          'content@admin': {
            template:
              '<sc-protected-module module="scouting" header="general.general.Scouting" image-placeholder="/img/scouting-module.jpg">' +
              '<template-builder />' +
              '</sc-protected-module>',
          },
        },
      })
      .state('admin.templates.editor', {
        url: '/:templateId',
        views: {
          editor: {
            template:
              '<template-editor template="$templateEditorCtrl.selectedTemplate"' +
              ' can-edit-template="$ctrl.canEditTemplate"' +
              ' on-template-delete="$ctrl.confirmDeleteTemplate($template)"' +
              ' on-template-update="$ctrl.updateTemplate($template, $successCallback)"' +
              ' ></template-editor>', // $ctrl is from <template-builder>

            controller: function (selectedTemplate) {
              this.selectedTemplate = selectedTemplate;
            },
            controllerAs: '$templateEditorCtrl',
            resolve: {
              selectedTemplate: function ($stateParams, ScoutingTemplateResource) {
                return ScoutingTemplateResource.get({id: $stateParams.templateId}).$promise;
              },
            },
          },
        },
      });
  }
})();
(function () {
  angular
    .module('app.admin.userRights', [
      'app.common',
      'app.admin.common',
      'ui.router',
      'ui.router.router',
      'pascalprecht.translate',
    ])
    .config(adminUserRightsModuleConfig);

  function adminUserRightsModuleConfig($stateProvider) {
    $stateProvider.state('admin.userRights', {
      url: '/user-rights',
      views: {
        'content@admin': {
          template: '<user-rights />',
        },
      },
    });
  }
})();
(function () {
  angular
    .module('app.admin.groups', [
      'app.common',
      'app.admin.common',
      'ui.router',
      'ui.router.router',
      'pascalprecht.translate',
    ])
    .config(adminGroupsModuleConfig);

  function adminGroupsModuleConfig($stateProvider) {
    $stateProvider.state('admin.groups', {
      url: '/groups',
      views: {
        'content@admin': {
          template: '<user-groups />',
        },
      },
    });
  }
})();
(function () {
  angular
    .module('app.admin.playerRating', [
      'app.common',
      'app.admin.common',
      'ui.router',
      'ui.router.router',
      'pascalprecht.translate',
    ])
    .config(adminPlayerRatingModuleConfig);

  function adminPlayerRatingModuleConfig($stateProvider) {
    $stateProvider.state('admin.playerRating', {
      url: '/player-rating',
      views: {
        'content@admin': {
          template: '<player-rating />',
        },
      },
    });
  }
})();
(function () {
  angular
    .module('app.admin.levels', [
      'app.common',
      'app.admin.common',
      'ui.router',
      'ui.router.router',
      'pascalprecht.translate',
    ])
    .config(adminLevelsModuleConfig);

  function adminLevelsModuleConfig($stateProvider) {
    $stateProvider.state('admin.levels', {
      url: '/levels',
      views: {
        'content@admin': {
          template: '<user-levels />',
        },
      },
    });
  }
})();
(function () {
  angular
    .module('app.admin.linkServices', [
      'app.common',
      'app.admin.common',
      'ui.router',
      'ui.router.router',
      'pascalprecht.translate',
    ])
    .config(linkServicesModuleConfig);

  function linkServicesModuleConfig($stateProvider) {
    $stateProvider.state('admin.linkServices', {
      url: '/link-services',
      views: {
        'content@admin': {
          template: '<link-services />',
        },
      },
    });
  }
})();
(function () {
  angular
    .module('app.admin.billing', [
      'app.common',
      'app.admin.common',
      'ui.router',
      'ui.router.router',
      'pascalprecht.translate',
    ])
    .config(adminBillingModuleConfig);

  function adminBillingModuleConfig($stateProvider) {
    $stateProvider
      .state('admin.billing', {
        url: '/billing?client_secret&source',
        views: {
          'content@admin': {
            template: '<sc-billing />',
          },
        },
        resolve: {
          title: function (Tabs) {
            Tabs.setWindowTitle('Billing');
          },
        },
      })
      .state('admin.billing.invoice', {
        url: '/invoices/:invoiceId',
        views: {
          'content@admin': {
            template: '<invoice-detail />',
          },
        },
        resolve: {
          title: function (Tabs) {
            Tabs.setWindowTitle('Invoice Detail');
          },
        },
      })
      .state('admin.plans', {
        url: '/plans',
        views: {
          'content@admin': {
            template: '<sc-plans />',
          },
        },
        resolve: {
          title: function (Tabs) {
            Tabs.setWindowTitle('Plans');
          },
        },
      })
      .state('admin.plans.changePlan', {
        url: '/change-plan',
        views: {
          subview: {
            template:
              '<change-plan plans="$ctrl.plans" subscription="$ctrl.currentSubscription" pending-plan-downgrades="$ctrl.pendingPlanDowngrades" />',
          },
        },
        resolve: {
          title: function (Tabs) {
            Tabs.setWindowTitle('Change Plan');
          },
        },
      })
      .state('admin.plans.addPackage', {
        url: '/change-package',
        views: {
          subview: {
            template:
              '<change-package plans="$ctrl.plans" discounts="$ctrl.discounts" subscription="$ctrl.currentSubscription" pending-package-removals="$ctrl.pendingPackageRemovals" />',
          },
        },
        resolve: {
          title: function (Tabs) {
            Tabs.setWindowTitle('Add Package');
          },
        },
      });
  }
})();
