(function () {
  'use strict';

  angular.module('app.settings', ['app.common', 'ui.router']).config(settingsModuleConfig);

  function settingsModuleConfig($stateProvider) {
    $stateProvider
      .state('settings', {
        url: '/settings',
        abstract: true,
        redirectTo: 'settings.preferences',
        views: {
          '@': {
            template: '<settings-container />',
          },
          'header@settings': {
            templateUrl: 'settings/common/views/header.html',
            controller: 'HeaderController',
          },
        },
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/settings/sources.js',
              '/app/js/communication/sources.js',
            ]);
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('Settings');
          },
        },
      })
      .state('settings.preferences', {
        url: '',
        views: {
          'settings-subview@settings': {
            template: '<settings-preferences />',
          },
        },
      })
      .state('settings.logins', {
        url: '/logins',
        views: {
          'settings-subview@settings': {
            template: '<login-sessions />',
          },
        },
      })
      .state('settings.communication', {
        url: '/communication',
        views: {
          'settings-subview@settings': {
            template: '<settings-communication />',
          },
        },
      })
      .state('settings.phone', {
        url: '/phone',
        views: {
          'settings-subview@settings': {
            template: '<settings-phone />',
          },
        },
      });
  }
})();
